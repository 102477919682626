import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import AboutSec from "../../Component/AboutSec/AboutSec"; 
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
      
        <title>Innovative Digital Transformation Solutions for Business Growth - TekCorp Limited</title> 

        <link rel="canonical" href="https://www.tekcorpltd.com/company" />

        <meta name="description" content="Discover TekCorp Limited scalable and innovative solutions to propel your business forward. Achieve growth with our strategic digital transformation expertise." />

      </Helmet>
      <Hero
        subHeading={"Big Ideas, Starts Here"}
        heading={"Skyrocket your Digital Transformation"}
      />
      <AboutSec />
      {/* <Partnerships /> */}
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default About;
