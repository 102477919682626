import React from 'react'; 
import { useNavigate } from "react-router-dom";
import './SeamlessExp.css';
import { SeamlessExperienceEcommerceData } from '../Constants/DataConstants';

const Card = ({ icon, title, description }) => (
    <div className='col-lg-4 col-md-6 col-12'>
        <div className='common-card-wrapper'>
            <div className='card-content'>
                <div className='card-icon-wrapper'>
                    <img className='img-fluid' src={icon} alt={`${title} icon`} />
                </div>
                <h3 className='h2_main'>{title}</h3>
                <p className='para_main'>{description}</p>
            </div>
        </div>
    </div>
);

const SeamlessExperience = () => {  
    const navigate = useNavigate();
    return (
        <div className='seamless-experience-ecom-wrapper'>
            <section className='container'>
                <div className='seamless-strategies-wrapper'>
                    <h2 className='h2_main'>Empowering Ecommerce with Tailored Solutions</h2>
                    <p className='para_main'>
                        In the rush of digital transformation, catching up with the right tools to boost your online business is essential. We give you a leg up over the competition by fusing state-of-the-art technology with a user-friendly interface, making us your go-to partner for ecommerce website development Dubai,UAE businesses can rely on
                    </p>
                    <button onClick={() => { navigate('/contact') }} className='button_simple black'>Get Started</button>
                </div>
            </section>

            <section className='container'>
                <div className='row d-flex justify-content-center gy-4'>
                    {SeamlessExperienceEcommerceData.map((card, index) => (
                        <Card
                            key={index}
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default SeamlessExperience;