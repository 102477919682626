import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import Partnerships from "../../Component/Partnerships/Partnerships";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const WebDevelopment = () => {
  return (
    <>
     <Helmet>
        <title>Professional Website Design & Development Service in Dubai, UAE</title>

        <meta name="description" content="Get innovative website design & development services in Dubai, UAE with TekCorp Limited. Build high-performance, secure, and user-friendly websites tailored to your goals."/>
        
        <meta
          name="keywords"
          content="website development service in UAE"
        />
        <meta
          name="keywords"
          content="best website development service in Dubai"
        />
        <meta
          name="keywords"
          content="custom website development service"
        />
        <meta
          name="keywords"
          content="Website Development Service in Dubai"
        />
        <meta
          name="keywords"
          content="Website Design in Dubai"
        />
        <meta
          name="keywords"
          content="Website Design and Development in Dubai"
        />
        <meta
          name="keywords"
          content="Custom Website Development Dubai"
        />
        <meta
          name="keywords"
          content="Web Design Company in Dubai"
        />

        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/web-development-services-in-dubai-uae"
        />
        
      </Helmet>
      <Hero
        subHeading={"Your Vision, Our Code"}
        heading={"Innovative Web Engineering Solutions"}
      />
      <Partnerships web={true} />
      <ImgByContent
        img={"/images/web1.png"}
        services={true}
        span={"Discover, "}
        number={"01"}
        heading={"Consult, and Define Focus"}
        text={
          "Our team collaborates closely with yours to identify a focus and set quantifiable targets. We collect information based on various criteria, including your brand's purpose, main message, target audience, unique selling point, and competitors."
        }
        text2={
          "We then construct a technical specification document that summarizes all of this information and ensures that all key stakeholders agree with the activities, deliverables, dates, and KPIs set."
        }
      />
      <ImgByContent
        img={"/images/web2.jpg"}
        reverse={true}
        services={true}
        span={"Implementing "}
        number={"02"}
        heading={"Robust Tech Stacks"}
        text={
          "We offer full-cycle web development solutions, taking complete responsibility for your project from concept to implementation. Whether you need a custom coded website, a sophisticated and modern eCommerce store, or a multi-layered online application, we deliver tailored solutions to meet your needs."
        }
        text2={
          "Our team provides a scalable, safe, and effective solution. Our developers have extensive expertise and experience with all aspects of the popular technologies, including MEAN, MERN, Lamp, and other popular stacks."
        }
      />
      <ImgByContent
        img={"/images/web3.png"}
        services={true}
        span={"Client-centric "}
        number={"03"}
        heading={"approach at hand"}
        text={
          "Choosing us means working with a staff that will have your best interests in mind. We stay up to date on industry developments and ensure that your website meets current requirements. This strategy blends our clients' demands and goals into our work, ensuring that each given product is unique and has an easy interface for users."
        }
        text2={
          "Our team of experts works with full dedication and commitment and ensures that we become a trusted partner in navigating the digital landscape of our client’s website."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default WebDevelopment;
