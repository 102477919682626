import React from "react";
import styles from "./HomeHero.module.css";
import Header from "../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeHero = () => {
  return (
    <>
      <div className={styles.Hero}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Header />
        </div>
        <video
          className={styles.video}
          src={
            "https://tekcorp.s3.ap-south-1.amazonaws.com/1725630009856-Dubai%202%20Sec%20Compressed.mp4"
          }
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
        />
        <Container style={{ flexGrow: 1 }}>
          <Row>
            <Col lg={7}>
              <div className={styles.Hero_Content}>
                <h3 className="h3_main">Modifying Ideas into Digital Growth</h3>
                <h1 className="heading_capital">
                  Tekcorp - Your
                  <br /> Partner in Digital Transformation
                </h1>
                <p style={{ color: "#fff" }} className="para_main">
                  Explore our Services, Products, and Integrations for a Future-Ready Business Ecosystem. As a leading digital solutions company in Dubai,UAE, we offer tailored services, proven products, and seamless integrations – elevating your business and empowering your growth.
                </p>
                <div className={styles.link} to="/contact">
                  <Link to="/contact">Get a Quote</Link>
                  <img src="/images/icons/arrow.svg" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomeHero;
