import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./InfoSec.module.css";

const InfoSec = ({ data = {} }) => {
  return (
    <div className={styles.main}>
      <Container>
        <Row className="gy-5">
          <Col className={styles.col1} lg={5}>
            <h1 className="h1_small">
              <span>{data.highlightedWord} </span>
              <br />
              {data.mainHeading}
            </h1>
            <p className="para_main">{data.para}</p>
            <a style={{ textDecoration: "none" }} href="tel: +92 335 7221733">
              <button className="button_simple black">Get Started</button>
            </a>
          </Col>
          <Col className={styles.col2} lg={7}>
            <Col
              style={{
                padding: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={`${styles.box} ${styles.box_height_auto}`}>
                <img src="/images/icons/1-common.png" />
                <h3 className="h2_main"> {data.subHeading3}</h3>
                <p className="para_main">{data.subPara3}</p>
              </div>
            </Col>
            <Col
              className={styles.boxContCol}
              style={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={styles.box}>
                <img src="/images/icons/2-common.png" />
                <h3 className="h2_main"> {data.subHeading1}</h3>
                <p className="para_main">{data.subPara1}</p>
              </div>
              <div className={styles.box}>
                <img src="/images/icons/3-common.png" />
                <h3 className="h2_main"> {data.subHeading2}</h3>
                <p className="para_main">{data.subPara2}</p>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoSec;
