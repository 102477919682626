import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import ContactPageForm from "../../Component/ContactPageForm/ContactPageForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - TekCorp Limited</title>
        <link rel="canonical" href="https://www.tekcorpltd.com/contact" />
        <meta name="description" content="Reach out to our team for expert guidance and support. We are here to help with your inquiries and provide solutions tailored to your needs." />
      </Helmet>
      <Hero
        subHeading={"Reach Out for Expert Assistance"}
        heading={"Get in Touch with Us"}
      />
      <ContactPageForm />
      <Footer />
    </>
  );
};

export default Contact; 