export const SeamlessExperienceEcommerceData = [
    {
        icon: '/images/icons/inventory-management-icon.svg',
        title: 'Inventory Management',
        description:
            'Stay on top of your stock with smart inventory solutions. Our systems help you manage, track, and optimize your inventory in real-time, ensuring efficiency and accuracy at every step.',
    },
    {
        icon: '/images/icons/secure-payments-icon.svg',
        title: 'Secure Payments',
        description:
            'Deliver a smooth and secure checkout experience with advanced payment solutions. We prioritize customer trust with reliable, fast, and encrypted payment gateways that meet global security standards.',
    },
    {
        icon: '/images/icons/intuitive-interface-icon.svg',
        title: 'User Interface',
        description:
            'Captivate your audience with an intuitive and responsive interface. Our user-centric designs ensure effortless navigation, boosting user engagement, satisfaction, and conversions.',
    },
];

