import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import BlogsPageSec from "../../Component/BlogsPageSec/BlogsPageSec";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Latest Articles & Tech Industry Insights | TekCorp Limited</title> 

        <link rel="canonical" href="https://www.tekcorpltd.com/insights" />

        <meta name="description" content="Stay updated with the latest tech trends and insights from TekCorp Limited. Discover expert articles on innovation, industry news, and technology solutions." />
      </Helmet>
      <Hero
        subHeading={"Explore Our Latest Articles & Industry Trends"}
        heading={"Insights & Updates"}
      />
      <BlogsPageSec />
      <Footer />
    </>
  );
};

export default Blog; 