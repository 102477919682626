import React from "react";
import Portfolio from "../../Component/Portfolio/Portfolio";
import Footer from "../../Component/Footer/Footer";
import HomeHero from "../../Component/HomeHero/HomeHero";
import Specialization from "../../Component/Specialization/Specialization";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import Blogs from "../../Component/Blogs/Blogs";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Dubai’s #1 Hub for Cutting-Edge Digital Solutions</title>

        <meta property="og:type" content="website" />

        <meta property="og:title" content="Dubai’s #1 Hub for Cutting-Edge Digital Solutions" />

        <meta property="og:description" content="Elevate your business with TekCorp Limited, the best Digital Solutions in Dubai, UAE. Tailored software solutions, Digital Marketing services, web development, and more." />

        <meta property="og:url" content="https://www.tekcorpltd.com/" />

        <meta property="og:image" content="https://www.tekcorpltd.com/images/logo.svg" />

        <meta property="og:image:width" content="880" />

        <meta property="og:image:height" content="660" />

        <meta property="og:locale" content="en_ae" />

        <meta property="og:site_name" content=" TekCorp Ltd " />

        <meta name="description" content="Elevate your business with TekCorp Limited, the best Digital Solutions company in Dubai, UAE. Tailored software solutions, SEO services, web development, and more."></meta>

        <meta 
          name="keywords" 
          content="software development company in dubai uae" 
        />

        <meta 
          name="keywords" 
          content="software development company dubai uae" 
        />

        <meta 
          name="keywords" 
          content="software development company in uae" 
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "TekCorp Limited",
            "url": "https://www.tekcorpltd.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.tekcorpltd.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "TekCorp Limited",
            "alternateName": "TekCorp Limited",
            "url": "https://www.tekcorpltd.com/",
            "logo": "https://www.tekcorpltd.com/images/logo.svg",
            "sameAs": [
              "https://www.linkedin.com/company/TekCorpltd/",
              "https://www.facebook.com/profile.php?id=61563448207086",
              "https://www.instagram.com/TekCorpltd/"
            ]
          }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "TekCorp Limited",
            "image": "https://www.tekcorpltd.com/images/logo.svg",
            "@id": "",
            "url": "https://www.tekcorpltd.com/",
            "telephone": "",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "",
              "addressLocality": "Dubai",
              "postalCode": "",
              "addressCountry": "AE"
            } ,
            "sameAs": [
              "https://www.facebook.com/profile.php?id=61563448207086",
              "https://www.instagram.com/TekCorpltd/",
              "https://www.linkedin.com/company/TekCorpltd/"
            ] 
          }
          )}
        </script>
      </Helmet>
      <HomeHero />
      <Specialization />
      <ImgByContent
        home={true}
        reverse={true}
        img={"/images/home-img-1.webp"}
        text={
          "At TekCorp, we enable businesses to thrive through tailored strategies that fuel innovation and deliver measurable success. Leveraging the power of digital transformation, we craft impactful solutions that empower your vision to reach new heights."
        }
        text2={
          "Our team of experts provides end-to-end support with precise guidance at every stage of your business journey. From bridging gaps to driving next-generation solutions, TekCorp ensures you stay ahead by turning vision into impactful reality."
        }
        buttonText={"Let's Connect"}
        buttonLink={"/contact"}
      />
      <Portfolio />
      <Blogs />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Home;