import React from "react";
import Hero from "../../Component/Hero/Hero";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import CaseStudies from "../../Component/CaseStudies/CaseStudies";
import { Helmet } from "react-helmet";

const CaseStudy = () => {
  return (
    <>
      <Helmet>
        <title>Case Studies | TekCorp Limited</title> 

        <link rel="canonical" href="https://www.tekcorpltd.com/case-studies" />

        <meta name="description" content="Discover how TekCorp Limited leads innovation with cutting-edge tech solutions. Explore our recent client case studies showcasing impactful and tailored results."/>

      </Helmet>
      <Hero
        subHeading={"Leading the Way in Tech Solutions"}
        heading={"Discover How TekCorp Innovates Solutions"}
      />
      <CaseStudies />
      <ContactForm />
      <Footer />
    </>
  );
};

export default CaseStudy;
