import React from 'react'; 
import Hero from '../../Component/Hero/Hero';
import Footer from '../../Component/Footer/Footer';

const NotFoundPage = () => {
    return (
        <>
            <Hero
                subHeading={"Oops! Page Not Found"}
                heading={"The page you’re looking for doesn’t exist."}
            /> 
            <Footer />
        </>
    )
}

export default NotFoundPage;