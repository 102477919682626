export const Links1 = [
  {
    title: "Ecommerce Development",
    path: "/ecommerce-development-service-in-dubai-uae",
  },
  {
    title: "Web Development",
    path: "/web-development-service-in-dubai-uae",
  },
  {
    title: `CMS Development`,
    path: "/cms-website-development-in-dubai-uae",
  },
  {
    title: `App Development`,
    path: "/mobile-app-development-dubai-uae",
  },
  {
    title: "UI/UX Design",
    path: "/ui-ux-design-company-dubai-uae",
  },
];
export const Links2 = [
  {
    title: "Search Engine Optimization",
    path: "/search-engine-optimization-services-dubai-uae",
  },
  {
    title: "Social Media Marketing",
    path: "/social-media-marketing-service-in-dubai-uae",
  },
  {
    title: "Google Ads",
    path: "/google-ads-services-agency-in-dubai-uae",
  },
];
export const Links3 = [
  {
    title: "Location Pages Portal",
    path: "/location-pages-portal-development",
  },
  {
    title: "LMS System",
    path: "/lms-system-development",
  },
  {
    title: "Blogs Portal",
    path: "/blog-portal-development",
  },
];
