import React from "react";
import Hero from "../../Component/Hero/Hero";
import Reviews from "../../Component/Reviews/Reviews";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Footer from "../../Component/Footer/Footer";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import InfoSec from "../../Component/InfoSec/InfoSec";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import { Helmet } from "react-helmet";

const CMSDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>CMS Website Development Service in Dubai, UAE</title>

        <meta
          name="keywords"
          content="cms development uae"
        />

        <meta name="keywords" content="cms website development dubai" />

        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/cms-website-development-services-in-dubai-uae"
        />

        <meta name="description" content="Discover top-tier CMS integration solutions with TekCorp Limited. Scalable, customizable, and intuitive CMS for enhanced website management and performance." />

      </Helmet>
      <Hero
        subHeading={"Seamless CMS Integration for Web Development"}
        heading={"Delivering Efficient CMS Solutions"}
      />
      <InfoSec
        data={{
          highlightedWord: "Building Robust",
          mainHeading: "CMS Solutions",
          para: `At TekCorp, we provide top-tier CMS integration solutions for seamless web management. Our CMS solutions are fully integrated, intuitive, easily scalable, and highly customizable, allowing your website to expand in line with your company’s expansion. With our expertise in CMS development UAE, we ensure your platform is optimized for growth and adaptability.`,
          subHeading1: "Responsive Design",
          subPara1: `We create effective design to attract users and ensure your website functions flawlessly. Our skilled developers help increase user engagement and retention.`,
          subHeading2: "Ongoing Support",
          subPara2: `Count on our devoted assistance for continuous website maintenance. We ensure that your digital environment functions smoothly by providing ongoing help and upgrades.`,
          subHeading3: "Data-Driven Decisions",
          subPara3: `We rely on the quantitative approach from the foundation of the digital strategies. With our development approach incorporating expertise into your site, we can identify areas that need improvements and make them for higher interaction, increased site effectiveness, and increased conversion rates.`,
        }}
      />
      <ImgByContent
        img={"/images/cms1.jpg"}
        services={true}
        span={"Tailored "}
        number={"01"}
        heading={"CMS Solutions for Your Business Needs"}
        text={
          "Tekcorp has a unique approach to crafting a website that involves a lot of creativity. We specialize in developing web solutions tailored to specific business needs using WordPress, Wix, and Joomla as the main platforms, all of which are known for their powerful capabilities in meeting various business needs."
        }
        text2={
          "The core of our strategy is a thorough analysis of your objectives and subsequent creation of a CMS-based website that gives you full control over your content to the extent that it is delivered effortlessly to the user."
        }
      />
      <ImgByContent
        img={"/images/cms2.jpg"}
        reverse={true}
        services={true}
        span={"Effortless "}
        number={"02"}
        heading={"CMS Integration for Seamless Content Management"}
        text={
          "Our approach ensures that managing your website’s content is intuitive and efficient, leveraging top-tier solutions that streamline every aspect of content handling. We integrate powerful platforms and provide a full spectrum of web services to deliver exceptional results tailored to your needs."
        }
        text2={
          "Our development strategy focuses on creating adaptable solutions that evolve with your business. We design CMS systems that not only meet your current requirements but also scale with your company’s growth, ensuring long-term flexibility and continued effectiveness."
        }
      />
      <ImgByContent
        img={"/images/cms3.jpg"}
        services={true}
        span={"Elevate "}
        number={"03"}
        heading={"Your E-Commerce with Tailored CMS Solutions"}
        text={
          "For industries focused on online business, our CMS solutions utilize robust tools like Magento and Shopify. Renowned for their flexibility, scalability, and comprehensive feature sets, these platforms are ideal for creating engaging and efficient online stores."
        }
        text2={
          "Our team of e-commerce specialists customizes your website to fully leverage these platforms, ensuring seamless integration of product management, payment gateways, inventory control, and more. We tailor each solution to enhance your online store's performance and effectiveness, providing you with a powerful foundation for growth."
        }
      />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default CMSDevelopment;
