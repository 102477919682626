import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Links1, Links2, Links3 } from "./Links";
import Mobilenav from "./Mobilenav";
import "./NavbarCS.css";
import "./mobileNav.css";
import styles from "./Header.module.css";

const Header = ({ background, noLinearBackground, blackVariant }) => {
  const navitate = useNavigate();
  const [selected5, setSelected5] = useState(false);
  const [style, setStyle] = useState({ display: "none" });

  const location = useLocation();
  const path = location.pathname;
  console.log(path);

  const hamburgerdropdown = (state) => {
    setSelected5(!state);
  };

  const mouseEnterHandler = () => {
    setStyle({ display: "block" });
  };
  const mouseLeaveHandler = () => {
    setStyle({ display: "none" });
  };

  return (
    <>
      <>
        <header
          style={{ background: background && background }}
          className={`${styles.desktopHeader}`}
        >
          <Container>
            <div className={styles.main}>
              <div className={styles.logo}>
                <NavLink onMouseEnter={() => mouseLeaveHandler()} to="/">
                  <img
                    alt="Tekcorp Ltd"
                    src={
                      blackVariant
                        ? "/images/logo_black.svg"
                        : "/images/logo.svg"
                    }
                  />
                </NavLink>
              </div>
              <div
                className={
                  blackVariant
                    ? `${styles.tabsBlack} ${styles.tabs}`
                    : styles.tabs
                }
              >
                <div
                  onMouseEnter={() => mouseLeaveHandler()}
                  className={
                    path === "/"
                      ? `${styles.tab} ${styles.activeTab}`
                      : styles.tab
                  }
                >
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.activeLink} ${styles.link}`
                        : blackVariant
                        ? `${styles.blackLink} ${styles.link}`
                        : blackVariant
                        ? `${styles.blackLink} ${styles.link}`
                        : styles.link
                    }
                  >
                    Home
                  </NavLink>
                </div>
                <div
                  onMouseEnter={() => mouseEnterHandler()}
                  onClick={mouseLeaveHandler}
                  className={
                    path === "/solutions"
                      ? `${styles.tab} ${styles.activeTab}`
                      : styles.tab
                  }
                >
                  <a
                    className={
                      blackVariant
                        ? `${styles.solutions} ${styles.blackLink} ${styles.link}`
                        : `${styles.solutions} ${styles.link}`
                    }
                  >
                    Solutions
                    <img
                      src={
                        blackVariant
                          ? "/images/icons/chevron-down-black.svg"
                          : "/images/icons/chevron-down.svg"
                      }
                    />
                  </a>
                </div>
                <div
                  onMouseEnter={() => mouseLeaveHandler()}
                  className={
                    path === "/company"
                      ? `${styles.tab} ${styles.activeTab}`
                      : styles.tab
                  }
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.activeLink} ${styles.link}`
                        : blackVariant
                        ? `${styles.blackLink} ${styles.link}`
                        : styles.link
                    }
                    to="/company"
                  >
                    Company
                  </NavLink>
                </div>

                <div
                  onMouseEnter={() => mouseLeaveHandler()}
                  className={
                    path === "/case-studies"
                      ? `${styles.tab} ${styles.activeTab}`
                      : styles.tab
                  }
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.activeLink} ${styles.link}`
                        : blackVariant
                        ? `${styles.blackLink} ${styles.link}`
                        : styles.link
                    }
                    to="/case-studies"
                  >
                    Case Studies
                  </NavLink>
                </div>
                <div
                  onMouseEnter={() => mouseLeaveHandler()}
                  className={
                    path === "/insights"
                      ? `${styles.tab} ${styles.activeTab}`
                      : styles.tab
                  }
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.activeLink} ${styles.link}`
                        : blackVariant
                        ? `${styles.blackLink} ${styles.link}`
                        : styles.link
                    }
                    to="/insights"
                  >
                    Insights
                  </NavLink>
                </div>
              </div>
              <Link onMouseEnter={() => mouseLeaveHandler()} to="/contact">
                <button
                  className={
                    blackVariant ? "button_simple black" : "button_simple"
                  }
                >
                  Get Started
                </button>
              </Link>
            </div>
          </Container>
        </header>

        <div
          onMouseLeave={mouseLeaveHandler}
          style={style}
          className={styles.drop_down}
        >
          <Container>
            <div
              onMouseLeave={mouseLeaveHandler}
              className={styles.drop_downContent}
              style={{
                zIndex: noLinearBackground ? "9999" : "1",
                top: noLinearBackground ? "90px" : "0",
              }}
            >
              <Row>
                <Col lg={7}>
                  <Row style={{ padding: "60px 16px 60px 60px" }}>
                    <Col lg={6}>
                      <div className={styles.listCol}>
                        <h4 className="h4_main">Design & Craft</h4>
                        {Links1.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles.listCol}>
                        <h4 className="h4_main">Build & Promote</h4>
                        {Links2.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                      <div className={styles.listCol}>
                        <h4 className="h4_main">Portal Development</h4>
                        {Links3.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col lg={5}>
                  <div className={styles.box}>
                    <div className={styles.head}>
                      <h4 className="h2_main">Get a Quote</h4>
                    </div>
                    <p className="para_main pb-3">
                      Request a personalized quote to find out how our services
                      can meet your unique needs.
                    </p>
                    <Link to="/contact">
                      <button>Learn more</button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>

      <div
        style={{ background: background && background }}
        className={`${styles.mobileHeader}`}
      >
        <header
          style={{
            width: "100%",
            zIndex: 99,
          }}
          className="header_cs_1"
        >
          <div className="container menu_cs_1 px-sm-3 px-4 d-flex justify-content-space-between align-content-center ">
            <h1 className="text-center" onClick={() => navitate("/")}>
              <img
                style={{ maxWidth: "185px" }}
                alt="Tekcorp Ltd"
                src={
                  blackVariant ? "/images/logo_black.svg" : "/images/logo.svg"
                }
                className="img-fluid"
              />
            </h1>
            <div
              className="bars_cs1"
              onClick={() => hamburgerdropdown(selected5)}
            >
              <button
                style={{ padding: "0px", color: blackVariant && "black" }}
                className={selected5 ? `fa solid fa-xmark` : `fa fa-bars`}
              ></button>
            </div>
          </div>
        </header>
        <Mobilenav transfer={selected5}></Mobilenav>
      </div>
    </>
  );
};

export default Header;
