import React from "react";
import Hero from "../../Component/Hero/Hero";
import Footer from "../../Component/Footer/Footer";
import CaseStudyPreview from "../../Component/CaseStudyPreview/CaseStudyPreview";
import ImgByContent from "../../Component/ImgByContent/ImgByContent";
import AppDevSec from "../../Component/AppDevSec/AppDevSec";
import ContactForm from "../../Component/ContactForm/ContactForm";
import Reviews from "../../Component/Reviews/Reviews";
import { Helmet } from "react-helmet";

const AppDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>Best Mobile App Development Company in Dubai, UAE</title>

        <meta name="keywords" content="app development dubai" />
        <meta name="keywords" content="mobile app development dubai" />
        <meta name="keywords" content="app development companies in dubai" />
        <meta name="keywords" content="mobile app development company in uae" />

        <link
          rel="canonical"
          href="https://www.tekcorpltd.com/mobile-app-development-services-dubai-uae"
        />

        <meta name="description" content="Boost your business with innovative mobile apps. We analyze trends, tailor solutions, and deliver exceptional user experiences to elevate your brand's performance."/>
      </Helmet>
      <Hero
        subHeading={"Delivering Tailored Digital Solutions to Drive Growth"}
        heading={"Elevate Your Business with Engaging Mobile Apps"}
      />
      <ImgByContent
        heading={"Pioneering Innovative Apps and Services"}
        img={"/images/app1.png"}
        text={`Revolutionize your business with the leading mobile app development company in Dubai, UAE, delivering cutting-edge applications that boost performance, create exceptional user experiences, and drive market leadership.`}
        appDev={true}
        reverse={true}
      />
      <AppDevSec />
      <CaseStudyPreview />
      <Reviews />
      <ContactForm />
      <Footer />
    </>
  );
};

export default AppDevelopment;
