import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./Component/Scroll To Top/ScrollToTop";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import CaseStudy from "./Pages/CaseStudy/CaseStudy";
import Contact from "./Pages/Contact/Contact";
import Blog from "./Pages/Blog/Blog";
import WebDevelopment from "./Pages/Web Development/WebDevelopment";
import AppDevelopment from "./Pages/App Development/AppDevelopment";
import UIUX from "./Pages/UIUX/UIUX"; 
import EcomDevelopment from "./Pages/Ecom Development/EcomDevelopment"; 
import LoginScreen from './Screens/LoginScreen';
import AdminScreen from './Screens/AdminScreen';
import CaseStudyListScreen from './Screens/CaseStudyScreens/CaseStudyListScreen';
import CaseStudyEditScreen from './Screens/CaseStudyScreens/CaseStudyEditScreen';
import BlogsScreen from './Screens/BlogsScreens/BlogsScreen';
import LocationScreen from './Screens/LocationScreens/LocationScreen';
import CMSDevelopment from "./Pages/CMS Development/CMSDevelopment";
import SEOPage from "./Pages/SEO/SEOPage";
import SMMPage from "./Pages/SMM/SMMPage";
import GoogleAds from "./Pages/GoogleAds/GoogleAds";
import IdvCaseStudy from "./Screens/CaseStudiesTemplate/IdvCaseStudy";
import Postdetails from "./Component/Blogs & Landing Page/Postdetails";
import Refund from "./Pages/Refund/Refund";
import Privacy from "./Pages/Privacy/Privacy";
import NotFoundPage from "./Pages/Home/NotFoundPage";

function App() {
  return (
    <Fragment>
      <ScrollToTop>
        <Routes>
         {/* <Route path="/*" element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<About />} />
          <Route path="/web-development-service-in-dubai-uae" element={<WebDevelopment />} />
          <Route path="/mobile-app-development-dubai-uae" element={<AppDevelopment />} />
          <Route path="/ui-ux-design-company-dubai-uae" element={<UIUX />} />
          {/* <Route path="/maintenance-and-support" element={<Maintenance />} /> */}
          <Route path="/ecommerce-development-service-in-dubai-uae" element={<EcomDevelopment />} />
          <Route path="/cms-website-development-in-dubai-uae" element={<CMSDevelopment />} />
          <Route path="/search-engine-optimization-services-dubai-uae" element={<SEOPage />} />
          <Route path="/social-media-marketing-service-in-dubai-uae" element={<SMMPage />} />
          <Route path="/google-ads-services-agency-in-dubai-uae" element={<GoogleAds />} />
          {/* <Route path="/lms-system" element={<LMSSystem />} />
          <Route path="/blog-portal-system" element={<BlogPortal />} />
          <Route path="/location-pages-development-portal" element={<LocationPagePortal />} /> */}
          <Route path="/case-studies" element={<CaseStudy />} />
          <Route path="/casestudy/:id" element={<IdvCaseStudy />} />
          <Route path="/insights" element={<Blog />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/refund-policy" element={<Refund/>} />
          <Route path="/blogs/:id" element={<Postdetails/>} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path='/login' element={<LoginScreen />}/>
          <Route path='/admin' element={<AdminScreen />}/>
          <Route path='/admin/casestudy' element={<CaseStudyListScreen />}/>
          <Route path='/admin/blogs' element={<BlogsScreen />}/>
          <Route path='/admin/location-pages' element={<LocationScreen />}/>
          <Route path='/admin/casestudy/:id/edit' element={<CaseStudyEditScreen />}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ScrollToTop>
    </Fragment>
  );
}

export default App;
